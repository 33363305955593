
import PricingTableItem from "@/components/landingPages/quickPurchase/pricingTables/PricingTableItem.vue";
import PricingTableDisclaimers from "@/components/pricingTable/PricingTableDisclaimers.vue";
import { PlanModel } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";

@Component({
  name: "PricingTable",
  components: { QuickPurchasePageTitle, PricingTableItem, PricingTableDisclaimers },
})
export default class PricingTable extends Vue {
  @Prop() private allPlans!: PlanModel[];

  get getDevopsList() {
    return this.allPlans?.filter(plan => plan.core === "DEVOPS");
  }

  get getDevsecopsList() {
    return this.allPlans?.filter(plan => plan.core === "DEVSECOPS");
  }

  get getNumOfPlans() {
    return this.allPlans?.length;
  }

  get wrapperStyles() {
    return {
      padding: this.getNumOfPlans > 5 ? "0 0px" : "0 50px",
    };
  }
}
