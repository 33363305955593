
import { AnyObject, PlanContent } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "PricingTableItemContent",
  components: {},
})
export default class PricingTableItemContent extends Vue {
  @Prop() private content!: PlanContent;

  openTexts: boolean = false;

  setOpenTexts() {
    this.openTexts = !this.openTexts;
  }

  get rotateIcon() {
    return this.openTexts ? "rotate-icon" : "";
  }

  clickableElement(trigger: boolean) {
    return trigger ? "clickable" : "";
  }

  tooltipHelperPointer(trigger: boolean) {
    return trigger ? "tooltipHelper" : "";
  }

  get isJas() {
    return this.content.isAdvancedDevSecOps;
  }

  get isDevOpsPlatformPackage() {
    return this.content.isDevOpsPlatformPackage;
  }

  get wrapperStyles() {
    let styles: AnyObject = {};
    styles["list-style-type"] = this.isJas || this.isDevOpsPlatformPackage ? "none" : "disc";
    styles["margin-left"] = "-1px";
    styles["margin-bottom"] = "-3px";
    styles["width"] = !this.isDevOpsPlatformPackage && !this.isJas ? "200px" : "";
    return styles;
  }

  get openAccordion() {
    return this.openTexts ? "accordion-content" : "";
  }

  contentIconClasses(content: PlanContent) {
    const classes: string[] = [];

    if (content.icon) {
      classes.push(content.icon.name);
      if (content.icon.fontSize) {
        classes.push(`fs-${content.icon.fontSize}`);
      }
      if (content.icon.cssClass) {
        classes.push(content.icon.cssClass);
      }
    }
    return classes;
  }

  contentTitleClasses(content: PlanContent) {
    const classes: string[] = [];

    if (content.titleBold) {
      classes.push("bold");
    }
    return classes;
  }
}
