
import { PlanModel } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

interface DisclaimerItem {
  number: number;
  legendArr: string[];
}

@Component({
  name: "PricingTableDisclaimers",
  components: {},
})
export default class PricingTableDisclaimers extends Vue {
  @Prop({ default: undefined })
  private colorClass!: string;
  @Prop({ default: undefined })
  private plans!: PlanModel[];

  disclaimerItems: DisclaimerItem[] = [];
  sentencesItems = [
    {
      number: "1",
      sentence: "CDN capabilities are currently available on AWS only.",
    },
    {
      number: "2",
      sentence:
        "JFrog Projects is included in the subscriptions as follows: Pro Team (3 active / no add-ons), Enterprise (30 active / no add-ons), Enterprise + (300 active / add-ons permitted for an additional $2,400 annually per pack of 100 additional projects.",
    },
    {
      number: "3",
      sentence:
        "The Security Pack is available with Enterprise subscriptions for an additional fee per month. Security Pack functionality is included in Enterprise+ subscriptions.",
    },
    {
      number: "4",
      sentence:
        "Distribution Edges may be utilized in self-hosted environments or as Cloud SaaS for hybrid distribution.",
    },
  ];

  get wrapperClasses() {
    const classes: string[] = [];
    if (!!this.colorClass) {
      classes.push(this.colorClass);
    }
    return classes;
  }
}
