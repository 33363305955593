
import PricingTableItemContent from "@/components/landingPages/quickPurchase/pricingTables/PricingTableItemContent.vue";
import { AnyObject, PlanModel } from "@/types/localtypes";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import logger from "@/util/logger";

@Component({
  name: "PricingTableItem.vue",
  components: { PricingTableItemContent },
})
export default class TableItem extends Vue {
  @Inject() readonly quickPurchaseBus!: Vue;
  @Prop() private plan!: PlanModel;
  @Prop() private numOfPlans!: number;

  get isInPromotion() {
    return !!this.plan.promotionItem;
  }

  get isFree() {
    return this.plan.paymentType === "FREE";
  }

  get isTrialEnterprise() {
    return this.plan.currentTrialPlan;
  }

  get promotionItem() {
    if (!this.isInPromotion) {
      return undefined;
    }
    return this.plan.promotionItem;
  }

  get wrapperStyles() {
    return {
      width: this.numOfPlans > 5 ? "240px" : "280px",
    };
  }

  get oldPriceLabel() {
    if (!this.promotionItem || !this.planPurchase) {
      return "";
    }
    return `${this.planPurchase.currency}${parseInt(this.promotionItem.oldPrice)}`;
  }

  get topContent() {
    return this.plan.content.filter(c => c.position !== "bottom");
  }
  get bottomContent() {
    return this.plan.content.filter(c => c.position === "bottom");
  }

  get planPurchase() {
    return this.plan.purchase;
  }

  get customOffer() {
    return this.plan.customOffer;
  }

  get buttonText() {
    return this.plan.buttonText;
  }

  get paymentType() {
    return this.plan.paymentType;
  }

  get dataGtmEventActionButton() {
    const action = this.buttonText ? this.buttonText.toLowerCase().replace(" ", "-") : "action";
    const paymentType = this.paymentType.toLowerCase().replace("_", "-");
    const suffix = `button-${action}-${paymentType}`;
    return `quick-purchase-pricing|${suffix}`;
  }

  handleOnClick() {
    if (this.plan.actionBtnDisabled) {
      return;
    }
    this.quickPurchaseBus.$emit("pricingItemActionClick", this.plan);
  }

  get startingPrefixLabel() {
    return this.plan.purchase ? this.plan.purchase.startingPrefix : "";
  }

  get underButtonInfoLabel() {
    return !this.creditCardInfoLabel?.length ? this.startFreeTrailLabel : this.creditCardInfoLabel;
  }

  get creditCardInfoLabel() {
    return this.plan.extra ? this.plan.extra.creditCardInfoLabel : "";
  }

  get startFreeTrailLabel() {
    return this.plan.extra ? this.plan.extra.startFreeTrial : "";
  }

  get getTitleClasses() {
    const classes: string[] = [];
    classes.push("bold", "fs-25", "mjf-green");
    return classes;
  }

  get actionBtnClasses() {
    const classes: string[] = [];
    if (this.plan.actionBtnDisabled) {
      classes.push("disabled");
    } else {
      classes.push("btn-mjf-green");
    }
    if (this.plan.isMostPopular && !this.plan.currentPlan) {
      classes.push("btn-most-popular");
    }

    return classes;
  }

  get mostPopular() {
    const classes: string[] = [];
    if (this.plan.isMostPopular && !this.plan.currentPlan) {
      classes.push("most-popular");
    }
    return classes;
  }

  specialBox(isAdvancedDevSecOps: boolean) {
    return isAdvancedDevSecOps ? "special" : "";
  }
}
